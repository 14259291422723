import {createRouter, createWebHistory} from 'vue-router'
import Home from './views/Home.vue'
import LoadData from './views/LoadData'
import TrainAlgorithm from './views/TrainAlgorithm'
import DetermineContingent from './views/DetermineContingent'
import Debug from './views/admin/Debug.vue'
import Secured from './views/Secured.vue'
import RedirectOrLogin from './views/RedirectOrLogin.vue'
import Unauthorized from './views/Unauthorized.vue'
import Settings from './views/UserSettings.vue'
import {keycloakAuthBeforeResolveGuard} from "./keycloak/authentication";
import AdminSettings from "@/views/admin/AdminSettings.vue";
import Organizations from "@/views/admin/Organizations.vue";
import Users from "@/views/admin/Users.vue";

const routes = [
    // Landing page that does not require you to be logged in.
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            needsAuthentication: false
        }
    },
    {
        path: '/load_data',
        name: 'Load Data',
        component: LoadData,
        meta: {
            needsAuthentication: true
        },
    },
    {
        path: '/train_algorithm',
        name: 'Train Algorithm',
        component: TrainAlgorithm,
        meta: {
            needsAuthentication: true
        },
    },
    {
        path: '/determine_contingent',
        name: 'Determine Contingent',
        component: DetermineContingent,
        meta: {
            needsAuthentication: true
        },
    },
    {
        path: '/secured',
        name: 'Secured',
        component: Secured,
        meta: {
            needsAuthentication: true,
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            needsAuthentication: true,
        },
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: Users,
        meta: {
            needsAuthentication: true,
            roleRequired: 'admin',
        },
    },
    {
        path: '/admin/organizations',
        name: 'Organizations',
        component: Organizations,
        meta: {
            needsAuthentication: true,
            roleRequired: 'admin'
        },
    },
    {
        path: '/admin/settings',
        name: 'AdminSettings',
        component: AdminSettings,
        meta: {
            needsAuthentication: true,
            roleRequired: 'admin'
        },
    },
    {
        path: '/admin/debug',
        name: 'Debug',
        component: Debug,
        meta: {
            needsAuthentication: true,
            roleRequired: 'admin'
        },
    },
    // Route that Keycloak redirects back to, which either forwards you to the page you wanted to visit, or sends you
    // to the login page.
    {
        path: '/redirect_or_login',
        name: 'RedirectOrLogin',
        component: RedirectOrLogin,
        meta: {
            needsAuthentication: false
        },
    },
    // The unauthorized page.
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized,
        meta: {
            needsAuthentication: false
        },
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

/**
 * Check authentication before resolving every route.
 */
router.beforeResolve(keycloakAuthBeforeResolveGuard)

export default router
