<template>
  <Dialog
    :visible="true"
    modal
    dismissable-mask
    :style="{ width: '100rem' }"
    :breakpoints="{ '1920px': '95vw' }"
    @update:visible="hideDialog"
  >
    <template #header>
      <div style="font-size: 30px; font-weight: 600">Track record: {{ labelDataSet.name }}</div>
    </template>

    <p>
      Label data set aangemaakt op: <strong>{{ dateTimeToString(labelDataSet.created) }}</strong>
      <br>Laatst gewijzigd op: <strong>{{ dateTimeToString(labelDataSet.updated) }}</strong>
    </p>

    <Accordion :active-index="[]" :multiple="true">
      <AccordionTab :header="'Adressen: '+ labelDataSet.label_data_entries.length">
        <view-edit-select-table
          :rows="labelDataSet.label_data_entries"
          :columns="label_data_columns"
        />
      </AccordionTab>
      <AccordionTab :header="'Renovatiemaatregelen van \''+ labelDataSet.renovation_concept.name + '\': ' + labelDataSet.renovation_concept.renovation_measures.length">
        <view-edit-select-table
          :rows="labelDataSet.renovation_concept.renovation_measures"
          :columns="measure_columns"
        />
      </AccordionTab>
      <AccordionTab :header="'Getrainde modellen: '+ labelDataSet.training_jobs.length">
        <view-edit-select-table
          :rows="labelDataSet.training_jobs"
          :columns="training_job_columns"
          :badges="trainingJobQualities"
        />
      </AccordionTab>
      <AccordionTab :header="'Contingenten: '+ labelDataSet.prediction_jobs.length">
        <view-edit-select-table
          :rows="labelDataSet.prediction_jobs"
          :columns="prediction_job_columns"
        />
      </AccordionTab>
    </Accordion>
    <slot />
  </Dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import {dateTimeToString, getTrainingQualities} from '@/helpers/functions';
import {building_parts} from "@/types";

const props = defineProps({
  labelDataSet: {
    type: Object,
    required: true,
  }
});

const trainingJobQualities = ref(null);

onMounted(() => {
  trainingJobQualities.value = getTrainingQualities(props.labelDataSet.training_jobs);
});

const building_part_options = ref([]);
const setBuildingParts = () => {
  for (let building_part_key in building_parts) {
    building_part_options.value.push({
      label: building_part_key, value: building_parts[building_part_key]
    })
  }
};
setBuildingParts();

const label_data_columns = ref([
  {field: 'postal_code', header: 'postcode', options: null},
  {field: 'house_number', header: 'huisnummer', options: null},
  {field: 'house_letter', header: 'huisletter', options: null},
  {field: 'house_number_annex', header: 'huisnr annex', options: null},
  {field: 'applicable', header: 'succesvol', options: null},
  {field: 'costs', header: 'kosten', options: null},
  {field: 'energy_savings', header: 'energie besparing', options: null},
]);

const training_job_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
]);

const measure_columns = ref([
  {field: 'name', header: 'Naam', options: null},
  {field: 'description', header: 'Beschrijving', options: null},
  {field: 'building_part', header: 'Gebouwonderdeel', options: building_part_options}
]);

const prediction_job_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
]);

const emit = defineEmits(['close']);

const hideDialog = () => {
  emit("close");
}

</script>

<style scoped>

</style>