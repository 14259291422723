<template>
  <h2>Admin Settings</h2>
  <KfpDefaultVersions ref="kfpDefaultVersionsRef" />
  <KfpConfigVersions @updated="onConfigVersionsUpdated" />
</template>

<script setup>
import {ref} from "vue";
import KfpDefaultVersions from '@/components/settings/KfpDefaultVersions.vue';
import KfpConfigVersions from "@/components/settings/KfpConfigVersions.vue";

const kfpDefaultVersionsRef = ref(null);

const onConfigVersionsUpdated = ()=> {
  kfpDefaultVersionsRef.value.queryTrainingModels();
};

</script>

