<template>
  <div class="flex flex-column h-full p-2">
    <div id="menubar">
      <Menubar
        :model="isAuthenticated ? items_logged_in : items"
      >
        <template #start>
          <img src="./assets/logo.png" alt="">
        </template>
        <template #item="{ item, props, hasSubmenu }">
          <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a :href="href" v-bind="props.action" :aria-hidden="false" @click="navigate">
              <span :class="item.icon" />
              <span class="ml-2">{{ item.label }}</span>
            </a>
          </router-link>
          <a v-else :href="item.url" :target="item.target" v-bind="props.action" :aria-hidden="false">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
          </a>
        </template>
      </Menubar>
    </div>

    <router-view />

    <Toast />
  </div>
</template>

<script setup>
import {computed, onBeforeMount, ref} from 'vue';
import {useKeycloak} from "./keycloak/authentication";

const {isAuthenticated, hasRole, getKeycloak, logout} = useKeycloak();
const isAdmin = computed(() => hasRole('admin'));

const firstName = ref();
const lastName = ref();

onBeforeMount(() => {
  if (isAuthenticated.value) {
    const kc = getKeycloak();
    firstName.value = kc.tokenParsed.given_name
    lastName.value = kc.tokenParsed.family_name
  }
})

const items = ref([
  {
    label: 'Home',
    route: '/',
    icon: 'pi pi-home',
  },
]);

const items_logged_in = ref([
  {
    label: 'Home',
    route: '/',
    icon: 'pi pi-home',
  },
  {
    label: 'Gegevens',
    route: '/load_data',
    icon: 'pi pi-upload',
  },
  {
    label: 'Modellen',
    route: '/train_algorithm',
    icon: 'pi pi-chart-line',
  },
  {
    label: 'Contingenten',
    route: '/determine_contingent',
    icon: 'pi pi-filter',
  },
  {
    label: 'Admin',
    icon: 'pi pi-prime',
    visible: isAdmin,
    items: [
      {
        label: 'Users',
        route: '/admin/users',
        icon: 'pi pi-user',
      },
      {
        label: 'Organizations',
        route: '/admin/organizations',
        icon: 'pi pi-building',
      },
      {
        label: 'Settings',
        route: '/admin/settings',
        icon: 'pi pi-cog',
      },
      {
        label: 'Debug',
        route: '/admin/debug',
        icon: 'pi pi-code',
      },
    ]
  },
  {
    label: 'Account',
    icon: 'pi pi-user',
    items: [
      {
        label: 'Settings',
        route: '/settings',
        icon: 'pi pi-cog',
      },
      {
        separator: true
      },
      {
        label: 'Logout',
        command: logout,
        icon: 'pi pi-sign-out',
      },
    ],
  }
]);

</script>

<style lang="scss">

html, body {
  height: 100%;
  margin: 0;
}

#app {
  height: 100%
}

.router-view {
  width: 100%
}

.user-menu.p-menubar {
  border: none !important;
}

.user-menu.p-menubar .p-submenu-list {
  z-index: 101;
  right: 0;
}

.router-link-active {
  font-weight: bold;
}

.row-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.row-badge.row-good {
  background: #C8E6C9;
  color: #256029;
}

.row-badge.row-bad {
  background: #FFCDD2;
  color: #C63737;
}

.row-badge.row-medium {
  background: #FEEDAF;
  color: #8A5340;
}

.p-card.p-component {
  width: 100%;
  margin-top: 1em;
  border-radius: 8px;
  border: 0.15rem solid darkgray;
  box-shadow: 0 5px 10px gainsboro;
}

.p-tooltip.p-component {
  max-width: 25em;
}

.p-datatable-loading-overlay.p-component-overlay {
  background-color: white;
}

.p-progress-spinner-circle {
  stroke: black !important;
}

</style>
