<template>
  <div class="pb-2">
    <Card>
      <template #title>
        Training: definiëer en start berekening
        <help-sidebar help-reference="Training: definieer en start berekening" />
      </template>
      <template #content>
        <label for="naam">Naam</label>
        <InputText
          id="naam"
          v-model="trainModelName"
          type="text"
          :invalid="trainModelName === ''"
          class="w-full"
        />
        <h4>Selecteer een track record</h4>
        <view-edit-select-table
          v-model:selected-row="selectedLabelDataSet"
          :rows="labelDataSets"
          :columns="ld_columns"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :selectable-row="true"
          :custom-actions="labelDataSetTableActions"
          :loading="loadingTrackRecords"
          @select="onSelectLabelDataSet"
        />
        <div v-if="hasRole('admin')">
          <h4>Selecteer training model en versie</h4>
          <div class="select-model">
            <label for="training-model" class="ml-2">Selecteer training model:</label>
            <Dropdown
              v-model="selectedTrainingModelName"
              :options="trainingModels"
              option-label="name"
              option-value="name"
              input-id="training-model"
              class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
            />
            <label for="training-model-versie" class="ml-2">Selecteer model versie:</label>
            <Dropdown
              v-model="selectedTrainingModelVersion"
              :options="trainingModelVersions[selectedTrainingModelName]"
              option-label="description"
              option-value="name"
              input-id="training-model-versie"
              class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
            />
            <label for="training-model-versie" class="ml-2">Selecteer configuratie versie:</label>
            <Dropdown
              v-model="selectedTrainingModelConfigVersion"
              :options="trainingModelConfigVersions[selectedTrainingModelName]"
              option-label="description"
              option-value="name"
              input-id="training-model-versie"
              class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
          appearance-none outline-none focus:border-primary"
              :invalid="selectedTrainingModelConfigVersion === null"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            class="flex align-items-center justify-content-center m-2"
            label="Start berekening"
            icon="pi pi-play"
            :disabled="buttonAdviceText != null"
            @click="onStartCalculation"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">{{ buttonAdviceText }}</div>
        </div>
      </template>
    </Card>
    <Card>
      <template #title>
        Training: lopende berekeningen en resultaten
        <help-sidebar help-reference="Training resultaten" />
      </template>
      <template #content>
        <view-edit-select-table
          :rows="trainingJobs"
          :columns="trainingJobColumns"
          :page-size="10"
          :deletable-row="true"
          :sort-option="new DataTableSortOption('updated', DataTableSortOrder.DESCENDING)"
          :badges="trainingResultsQualities"
          :custom-actions="jobActions"
          :loading="loadingTrainingJobs"
          @delete="onDeleteTrainingJob"
        />
      </template>
    </Card>
    <view-label-data-set-details
      v-if="labelDataSetToView!==null"
      :label-data-set="labelDataSetToView"
      @close="labelDataSetToView=null"
    />
    <view-training-details
      v-if="trainingJobToView!==null"
      :training-job="trainingJobToView"
      :show-details="!beforeDeleteCheck"
      @close="trainingJobToView=null"
    >
      <div v-if="beforeDeleteCheck">
        <Message severity="warn" :closable="false">
          Na 'Bevestig' worden de Contingenten hierboven ook verwijderd!
        </Message>
        <div class="flex flex-row-reverse flex-wrap">
          <Button
            label="Annuleer" icon="pi pi-times" class="p-button-text"
            @click="trainingJobToView=null; beforeDeleteCheck=false;"
          />
          <Button
            label="Bevestig" icon="pi pi-check" autofocus
            @click="deleteTrainingJob(trainingJobToView.id, trainingJobToView.name)"
          />
          <div class="flex align-items-center justify-content-center text-red-500 m-2">
            Getraind model verwijderen?
          </div>
        </div>
      </div>
    </view-training-details>
  </div>
</template>

<script setup>
import {computed, ref, onMounted} from "vue";
import {deleteData, fetchData, postData} from "@/api";
import {DataTableSortOption, DataTableSortOrder, TrainingSetup} from "@/types";
import ViewEditSelectTable from "@/components/base-components/ViewEditSelectTable";
import ViewTrainingDetails from "@/components/training/ViewTrainingDetails";
import ViewLabelDataSetDetails from "@/components/label_data_set/ViewLabelDataSetDetails";
import HelpSidebar from "@/components/help/HelpSidebar"
import {useKeycloak} from "@/keycloak/authentication";
import {errorToast, successToast, infoToast} from "@/toastService";

import {useKfpJobs} from "@/composables/kfpJobs";

const {
  getTrainingJobs,
  getTrainingJobsQualities,
  queryTrainingJobs,
} = useKfpJobs();
const trainingJobs = getTrainingJobs();
const trainingResultsQualities = getTrainingJobsQualities();

const {hasRole} = useKeycloak();

const trainModelName = ref("");

const loadingTrackRecords = ref(true);
const labelDataSets = ref([]);
const selectedLabelDataSet = ref(null);
const labelDataSetToView = ref(null);

const loadingTrainingJobs = ref(true);

const trainingModels = ref([]);
const trainingModelVersions = ref({});
const trainingModelConfigVersions = ref({});
const selectedTrainingModelName = ref(null);
const selectedTrainingModelVersion = ref(null);
const selectedTrainingModelConfigVersion = ref(null);

const trainingJobToView = ref(null);
const beforeDeleteCheck = ref(false);

const ld_columns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'renovation_concept.name', header: 'Renovatie Concept'},
  {field: 'nr_of_entries', header: 'Adressen'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
]);

const trainingJobColumns = ref([
  {field: 'name', header: 'Naam'},
  {field: 'user_full_name', header: 'Gebruiker'},
  {field: 'created', header: 'Aangemaakt', format: "datetime"},
  {field: 'updated', header: 'Laatst veranderd', format: "datetime"},
  {field: 'last_status', header: 'Laatste status'},
  {field: 'progress_fraction', header: 'Voortgang', format: "fraction_is_percentage"}
]);

onMounted(() => {
  queryLabelDataSets().then(() => loadingTrackRecords.value = false);
  queryTrainingJobs().then(() => loadingTrainingJobs.value = false);
  queryTrainingModels();
});

const queryLabelDataSets = async () => {
  labelDataSets.value = await fetchData("label_data_set/");
};

const onViewLabelDataSetDetails = async (labelDataSetId) => {
  labelDataSetToView.value = await fetchData("label_data_set/" + labelDataSetId);
};

const onSelectLabelDataSet = (labelDataSet) => {
  selectedLabelDataSet.value = labelDataSet;
};

const buttonAdviceText = computed(() => {
  if (selectedTrainingModelVersion.value == null) {
    return "Versie voor het training model is niet gezet, neem contact op met de admin.";
  } else if (trainModelName.value.replace(/\s/g, '') === '') {
    return "Vul naam in";
  } else if (!selectedLabelDataSet.value) {
    return "Selecteer een track record";
  }
  return null;
});

const onStartCalculation = async () => {
  let configFileName = selectedTrainingModelConfigVersion.value;
  let ts = new TrainingSetup(
      trainModelName.value,
      selectedLabelDataSet.value.id,
      selectedTrainingModelName.value,
      selectedTrainingModelVersion.value,
      configFileName,
  );

  infoToast("Training aanmaken...");

  // create training setup in db
  const ts_post = await postData("training/setup", ts);
  const ts_resp = await ts_post.json();

  if (ts_resp === null) {
    return;
  }
  // create and start training job
  const resp = await postData("training/job", {
    training_setup_id: ts_resp.id,
  });

  if (resp.ok) {
    successToast(
        "Training berekening '" + trainModelName.value + "' toegevoegd",
        "\n\nTrack record: '" + selectedLabelDataSet.value.name + "'"
    );
  } else {
    errorToast(
        "Training berekening '" + trainModelName.value + "' kan niet gecreëerd worden",
        "\n\nTrack record: '" + selectedLabelDataSet.value.name + "'"
    );
  }

  trainModelName.value = '';
  await queryTrainingJobs();
};

const queryTrainingModels = async () => {
  const resp = await fetchData("training/models");
  trainingModels.value = resp.cluster_models;
  const configVersionNames = resp.config_version_names;
  if (trainingModels.value.length === 0) {
    return;
  }
  selectedTrainingModelName.value = trainingModels.value[0].name;
  selectedTrainingModelVersion.value = trainingModels.value[0].kfp_version_default;
  selectedTrainingModelConfigVersion.value = trainingModels.value[0].kfp_config_version_default;

  if (hasRole('admin')) {
    for (const model of trainingModels.value) {
      trainingModelVersions.value[model.name] = [];
      for (let version of model.kfp_versions) {
        let description = version;
        if (model.kfp_version_default === version) {
          description = description + " (default)";
        }
        trainingModelVersions.value[model.name].push({name: version, description: description});
      }
      trainingModelConfigVersions.value[model.name] = [];
      for (let config of configVersionNames) {
        let description = config.replace(".toml", "");
        if (model.kfp_config_version_default === config) {
          description = description + " (default)";
        }
        trainingModelConfigVersions.value[model.name].push({name: config, description: description});
      }
    }
  } else if (selectedTrainingModelVersion.value === null) {
    errorToast("Training model versie niet gezet.", "Neem contact op met de admin.");
  } else if (selectedTrainingModelConfigVersion.value === null) {
    errorToast("Training model configuratie versie niet gezet.", "Neem contact op met de admin.");
  }
};

const onDeleteTrainingJob = async (trainingJobId) => {
  const resp = await fetchData("training/job/" + trainingJobId);
  if (resp.prediction_jobs.length > 0) {
    trainingJobToView.value = resp;
    beforeDeleteCheck.value = true;
  } else {
    infoToast("Training verwijderen...")
    await deleteTrainingJob(trainingJobId, resp.name);
  }
};

const deleteTrainingJob = async (trainingJobId, jobName) => {
  const del_resp = await deleteData("training/job/" + trainingJobId);
  await queryTrainingJobs();

  if (del_resp.ok) {
    successToast("Getrained model '" + jobName + "' verwijderd");
  } else {
    errorToast("Getrained model '" + jobName + "' kan niet verwijderd worden");
  }
  trainingJobToView.value = null;
  beforeDeleteCheck.value = false;
};

const onViewTrainingDetails = async (trainingJobId) => {
  trainingJobToView.value = await fetchData("training/job/" + trainingJobId);
};

const labelDataSetTableActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewLabelDataSetDetails,
    tooltip: 'Bekijk details'
  }
];

const jobActions = [
  {
    id: 1,
    icon: 'pi pi-eye',
    visible: () => true,
    handler: onViewTrainingDetails,
    tooltip: 'Bekijk details'
  }
];

</script>

<style lang="scss" scoped>
::v-deep(.p-scrollpanel) {
  p {
    padding: .5rem;
    line-height: 1.5;
    margin: 0;
  }

  &.custombar1 {
    .p-scrollpanel-wrapper {
      border-right: 9px solid var(--surface-ground);
    }

    .p-scrollpanel-bar {
      background-color: var(--primary-color);
      opacity: 1;
      transition: background-color .2s;

      &:hover {
        background-color: #007ad9;
      }
    }
  }
}

.select-model {
  display: grid;
  width: 500px;
  grid-template-columns: 250px 250px;
  align-items: center;
  justify-content: space-between;
}
</style>