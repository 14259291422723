<template>
  <Dialog
    :visible="true"
    modal
    dismissable-mask
    :style="{ width: '100rem' }"
    :breakpoints="{ '1920px': '95vw' }"
    @update:visible="hideDialog"
  >
    <template #header>
      <h2>Kubeflow pipelines config version: {{ kfpConfigVersion.version }}</h2>
    </template>
    <div class="">
      <b>versie: </b> {{ kfpConfigVersion.version }}
      <br><br>
      <b>training configuratie file naam: </b> {{ kfpConfigVersion.training_config_file_name }}
      <br>
      <b>training configuratie toml: </b>
      <br>
      <pre class="display: inline">{{ kfpConfigVersion.training_config_toml_str }}</pre>
      <br>
      <b>predictie configuratie file naam: </b> {{ kfpConfigVersion.prediction_config_file_name }}
      <br>
      <b>predictie configuratie toml: </b>
      <br>
      <pre class="display: inline">{{ kfpConfigVersion.prediction_config_toml_str }}</pre>
    </div>
  </Dialog>
</template>

<script setup>
import {ref, defineProps, defineEmits, onMounted} from "vue";

const props = defineProps({
  kfpConfigVersion: {
    type: Object,
    required: true,
  }
});

const kfpConfigVersion = ref(null);

onMounted(() => {
  kfpConfigVersion.value = props.kfpConfigVersion;
});

const emit = defineEmits(['close']);

const hideDialog = () => {
  emit("close");
};

</script>

<style scoped>

</style>