<template>
  <Card>
    <template #title>
      Welkom bij de Clustertool
    </template>
    <template #content>
      <p>
        U bent zojuist succesvol ingelogd en in deze uitleg zullen we u meenemen langs de stappen die nodig zijn voor
        het vormen van een contingent voor uw verduurzamingsoplossing. Het gevormde contingent is een verzameling van
        adressen (woningen) waarop uw verduurzamingsoplossing succesvol kan worden toegepast. Dit contingent kan worden
        gevormd voor heel Nederland, een deel van Nederland of voor een specifieke plek of stad.
      </p>
      <p>
        Deze uitleg neemt u mee door drie globale stappen om tot het gewenste resultaat te komen. Gedetailleerde
        informatie over wat er per stap moet gebeuren, welke informatie moet worden aangeleverd en in welk format dit
        moet worden aangeleverd zal door middel van informatie knoppen worden uitgelegd.
      </p>

      <p>Het bepalen van een contingent gaat als volgt (volg de tabbladen aan de bovenkant van de pagina):</p>

      <ol>
        <li>
          De eerste stap is
          <RouterLink to="/load_data">Gegevens</RouterLink>
          laden. Om een contingent te bepalen wordt begonnen met het definiëren van het renovatieconcept en het uploaden
          van de track record met adressenlijst die bij deze verduurzamingsoplossing hoort.
        </li>
        <li>
          De volgende stap is
          <RouterLink to="/train_algorithm">Modellen</RouterLink>
          trainen. In deze stap wordt op basis van de aangeleverde track record data een model getraind voor het
          verduurzamingsconcept aan de hand van een Machine Learning algoritme. Dit model wordt gebruikt om in de
          volgende stap een voorspelling te doen over op welke adressen het concept nog meer toepasbaar is, en dus welke
          woningen binnen uw contingent vallen.
        </li>
        <li>
          De derde stap is
          <RouterLink to="/determine_contingent">Contingenten</RouterLink>
          bepalen en bekijken. Aan de hand van het getrainde model uit stap 2 wordt in deze stap voorspeld op welke
          andere adressen de verduurzamingsoplossing nog meer succesvol kan worden toegepast in de toekomst. U kunt zelf
          de het gebied selecteren waarvoor u het contingent wilt bepalen (op het moment voor een specifieke stad of
          plaats). Addressen binnen aangemaakte contingenten kunnen worden weer gegeven op een kaart. Daarnaast is er
          ook algemene informatie beschikbaar over de woningen, o.a. over het bouwjaar, de oppervlakte en de omtrek van
          de woning.
        </li>
      </ol>

      <p>
        Het account en de inloggegevens zijn gekoppeld aan uw organisatie. De Clustertool is hierdoor gemakkelijk te
        gebruiken door meerdere collega’s. De resultaten van uitgevoerde berekeningen en gevormde Contigenten zijn voor
        iedereen binnen de organisatie zichtbaar en toegankelijk.
      </p>

      <h3>
        Succes met het vormen van uw contingent!
      </h3>
    </template>
  </Card>
</template>

<script setup lang="ts">
</script>
<style scoped>

ol li {
  margin-bottom: 10px;
  margin-left: 10px;
}

</style>