<template>
  <div class="grid p-2">
    <Card class="w-full mt-1">
      <template #title>
        Standaard training model versie per kubeflow pipeline
        <help-sidebar help-reference="" />
      </template>
      <template #content>
        <table>
          <tr>
            <th class="text-left w-15rem">Model naam</th>
            <th class="text-left w-15rem">Standaard versie</th>
            <th class="text-left">Standaard configuratie versie</th>
          </tr>
          <tr v-for="(versions, model_name) in trainingModelsWithVersions" :key="model_name">
            <td>{{ model_name }}</td>
            <td>
              <Dropdown
                v-model="selectedVersionForModels[model_name]"
                :options="versions"
                class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
                                      appearance-none outline-none focus:border-primary"
                @update:model-value="onUpdateDropdown(model_name)"
              />
            </td>
            <td>
              <Dropdown
                v-model="selectedConfigVersionForModels[model_name]"
                :options="trainingConfigVersions"
                class="text-base text-color surface-overlay border-1 border-solid surface-border border-round
                                              appearance-none outline-none focus:border-primary"
                @update:model-value="onUpdateDropdown(model_name)"
              />
            </td>
          </tr>
        </table>
      </template>
      <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
          <Button label="Opslaan" @click="saveKfpDefaultVersions" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import {onMounted, ref, defineExpose} from "vue";
import {fetchData, postData} from "@/api";
import HelpSidebar from "@/components/help/HelpSidebar.vue";

const kfpDefaultVersions = ref(null);
const selectedVersionForModels = ref({});
const selectedConfigVersionForModels = ref({});
const trainingModelsWithVersions = ref({});
const trainingConfigVersions = ref([]);

onMounted(() => {
  queryKfpDefaultVersions();
  queryTrainingModels();
});

const queryKfpDefaultVersions = async () => {
  kfpDefaultVersions.value = await fetchData("kfp_default_version/");
};

const queryTrainingModels = async () => {
  const resp = await fetchData("training/models");
  const trainingModels = resp.cluster_models;
  trainingConfigVersions.value = resp.config_version_names;
  if (trainingModels.length === 0) {
    return;
  }
  for (const model of trainingModels) {
    trainingModelsWithVersions.value[model.name] = [];
    selectedVersionForModels.value[model.name] = "";
    for (let version of model.kfp_versions) {
      if (model.kfp_version_default === version) {
        selectedVersionForModels.value[model.name] = version;
      }
    }
    trainingModelsWithVersions.value[model.name] = model.kfp_versions;

    if (trainingConfigVersions.value === null) {
      continue;
    }
    for (let fileName of trainingConfigVersions.value) {
      if (model.kfp_config_version_default === fileName) {
        selectedConfigVersionForModels.value[model.name] = fileName;
      }
    }
  }
};

const onUpdateDropdown = (model_name) => {
  updateVersion({
    model_name: model_name,
    version: selectedVersionForModels.value[model_name],
    config_version: selectedConfigVersionForModels.value[model_name],
  });
};

const updateVersion = (versionData) => {
  const versionIndex = kfpDefaultVersions.value.findIndex(version => version.model_name === versionData.model_name);
  if (versionIndex === -1) {
    kfpDefaultVersions.value.push({
      model_name: versionData.model_name,
      version: versionData.version,
      config_version: versionData.config_version,
    });
  } else {
    kfpDefaultVersions.value[versionIndex].version = versionData.version;
    kfpDefaultVersions.value[versionIndex].config_version = versionData.config_version;
  }
};

const saveKfpDefaultVersions = async () => {
  const kfpDefaultVersionsPost = [];
  for (const kfpDefaultVersion of kfpDefaultVersions.value) {
    kfpDefaultVersionsPost.push({
      model_name: kfpDefaultVersion.model_name,
      version: kfpDefaultVersion.version,
      config_version: kfpDefaultVersion.config_version,
    });
  }
  await postData("kfp_default_version/", kfpDefaultVersionsPost);
  console.log("Saved kfp default config versions");
};

defineExpose({queryTrainingModels});

</script>