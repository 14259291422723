import {useKfpJobs} from "@/composables/kfpJobs";
import {useKeycloak} from "@/keycloak/authentication";

const {isAuthenticated} = useKeycloak();

const {
    queryTrainingJobs,
    queryPredictionJobs
} = useKfpJobs();

export function runJobPolling(intervalSeconds) {
    setInterval(() => {
        if (!isAuthenticated.value) {
            return;
        }

        queryTrainingJobs();
        queryPredictionJobs();
    }, intervalSeconds * 1000);
}
